.video-container {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: white;
}
.video-content {
  position: relative;
  padding-bottom: 36.25%; /* 16:9 */
  height: 0;

  iframe {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.arena-background {
  /*background-image: url("https://depo.honvedfc.hu/web/bozsik-arena/header/video-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;*/

  img {
    max-width: 100%;
  }
}

.react-parallax {
  height: 600px;
}

.stadium-history-container {
  margin-top: 16px;
  background-color: #ac0000;
  background-image: url("https://depo.honvedfc.hu/web/bozsik-arena/header/bozsik-arena-history.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;

    h2 {
      margin: 0 0 32px 0;
      font-family: 'DINPro-Black';
      font-weight: normal;
      font-size: 38px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      font-family: 'DINPro-Medium';
      font-size: 1rem;
      text-align: center;
    }
  }
}

.gallery-container {
  margin-top: 0;
  background: #222222;

  .gallery-image-container {
    position: relative;

    .product-image__item {
      display: none;

      &.active {
        display: block;
      }
    }

    .gallery-pager {
      position: absolute;
      top: 0;
      width: 68px;
      height: 100%;
      //background-color: rgba(190,0,0,0.4);
      background-color: transparent;

      &__left {
        left: 0;

        .arrow-left {
          cursor: pointer;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -11px;
          width: 0;
          height: 0;
          border-top: 16px solid transparent;
          border-bottom: 16px solid transparent;
          border-right:22px solid white;
        }
      }

      &__right {
        right: 0;

        .arrow-right {
          cursor: pointer;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -8px;
          margin-left: -11px;
          width: 0;
          height: 0;
          border-top: 16px solid transparent;
          border-bottom: 16px solid transparent;
          border-left:22px solid white;
        }
      }
    }

    figure {
      img {
        max-width: 100%;
      }
    }
  }

}

.links-container {
  padding-top: 44px;

  &__element {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }
}

.events-container {
  .animated {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 48px;
    font-family: 'DINPro-Black';
    font-weight: normal;
    font-size: 38px;
    color: #be0000;
    text-align: center;
    text-transform: uppercase;
  }
  padding-top: 44px;

  .event-block {
    margin-right: 20px;
    width: calc(20% - 20px);
    float: left;
    background-color: #222222;
    height: 80px;
  }
}

.event-item {

  &__container {

    &:last-of-type {
      div {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }

  &__content {
    padding-top: 35px;
    padding-bottom: 25px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #222222;
    color: white;

    .crests {
      img {
        max-width: 65px;
      }
    }

    .type {
      p {
        margin: 0;
      }
    }
  }
}

.faq-container {
  margin-top: 36px;
  h2 {
    margin-bottom: 48px;
    font-family: 'DINPro-Black';
    font-weight: normal;
    font-size: 38px;
    color: #be0000;
    text-align: center;
    text-transform: uppercase;
  }

  .animated {
    display: block;
    width: 100%;
  }

  .faq-category {
    > a {
      margin-bottom: 1rem;
      padding: 1rem 0;
      display: block;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #be0000 !important;
      border: solid 1px #be0000;
      text-align: center;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #000000 !important;
        border-color: #000000;
      }
    }

    &.active {
      .faq-item-container {
        display: block;
      }
    }
  }


  .faq-item-container {
    display: none;
  }

  .faq-item {
    margin-bottom: 1rem;
    a {
      padding: 1rem 0;
      display: block;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #be0000;
      text-align: center;
      cursor: pointer;
      user-select: none
    }
    p {
      display: none;
      font-size: 1rem;
      text-transform: uppercase;
      color: #be0000;
      text-align: center;

      a {
        display: inline;
        font-size: 1rem;
        font-weight: normal;
        text-decoration: underline;

        &:hover {
          color: #000000;
          text-decoration: none;
        }
      }
    }
    &.active {
      p {
        display: block;
      }
    }
  }
}

.contact-container {
  margin-top: 50px;
  background-color: #ac0000;
  background-image: url("https://depo.honvedfc.hu/web/bozsik-arena/contact/contact-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .container {
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;

    figure {
      margin-bottom: 1.5rem;
      text-align: center;
      img {
        max-width: 100%;
      }
    }

    h2 {
      margin: 0 0 32px 0;
      font-family: 'DINPro-Black';
      font-weight: normal;
      font-size: 38px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      font-family: 'DINPro-Medium';
      font-size: 1rem;
      text-align: center;
    }
  }
}

footer {
  padding: 24px 0;

  p {
    margin: 0;
    font-family: 'DINPro-Black';
    font-weight: normal;
    font-size: 1rem;
    color: #be0000;
    text-transform: uppercase;

    a {
      color: #be0000;
      &:hover {
        text-decoration: underline;
        color: #000000;
      }
    }
  }
}

.padding5 {
  padding-left: 5px;
  padding-right: 5px;
}

input[type=text], textarea.form-control {
  border-radius: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

textarea.form-control {
  height: 80px;
  resize: none;
}

.btn-primary {
  padding: 12px 24px;
  background-color: #222222;
  border: none;
  text-transform: uppercase;

  &:hover {
    background-color: #444;
  }
}

.message-block {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #222222;
  color: #fff;
  /*&--success {
    border-left: solid 3px green;
  }

  &--error {
    border-left: solid 3px red;
  }*/
}

.stadium-tour-container {
  background-color: #ac0000;
  background-image: url(https://depo.honvedfc.hu/web/bozsik-arena/header/stadium-tour-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .container {
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
  }

  h2 {
    margin: 0 0 32px 0;
    font-family: 'DINPro-Black';
    font-weight: normal;
    font-size: 38px;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-family: 'DINPro-Medium';
    font-size: 1rem;
    text-align: center;
  }

  a {
    cursor: pointer;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }

}

@media all and (min-width: 992px) {
  .arena-background {
    background-image: url("https://depo.honvedfc.hu/web/bozsik-arena/header/video-background.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 700px;
  }

  .react-parallax {
    height: 400px;
  }

  .events-container {
    .animated {
      display: inline-block;
      width: 20%;
    }
  }

  .stadium-history-container,
  .stadium-tour-container
  {
    .container {
      p {
        text-align: justify;
      }
    }
  }
  .contact-container {
    .container {
      figure {
        margin-bottom: 0;
        text-align: center;
      }

      p {
        text-align: left;
      }
    }
  }
}

@media all and (min-width: 1200px) {
  .react-parallax {
    height: 520px;
  }
}

@media all and (min-width: 1440px) {
  .react-parallax {
    height: 600px;
  }
}

@media all and (min-width: 1680px) {
  .react-parallax {
    height: 700px;
  }
}