.main-navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #222222;
  border-bottom: solid 9px #be0000;
  height: 34px;

  ul {
    list-style: none;
    padding-left: 32px;

    li {
      display: inline-block;
      padding-top: 1px;
      padding-right: 20px;
      a {
        cursor: pointer;
        font-family: 'DINPro-Medium';
        color: white !important;
        text-transform: uppercase;
        font-size: 12px;

        &:hover {
          text-decoration: none;
        }
      }
      &.spacer {
        width: 180px;
      }
    }
  }
}

@media only screen and (min-width: 1075px) {
  .main-navigation {
    ul {
      li {
        a {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1140px) {
  .main-navigation {
    ul {
      li {
        a {
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .main-navigation {
    ul {
      li {
        a {
          font-size: 15px;
        }
      }
    }
  }
}