@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'DINPro-Black';
  src: url('assets/fonts/DINPro-Black.eot');
  src: url('assets/fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/DINPro-Black.woff') format('woff'),
  url('assets/fonts/DINPro-Black.woff2') format('woff2'),
  url('assets/fonts/DINPro-Black.ttf') format('truetype'),
  url('assets/fonts/DINPro-Black.svg#webfont') format('svg');
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url('assets/fonts/DINPro-Medium.eot');
  src: url('assets/fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/DINPro-Medium.woff') format('woff'),
  url('assets/fonts/DINPro-Medium.woff2') format('woff2'),
  url('assets/fonts/DINPro-Medium.ttf') format('truetype'),
  url('assets/fonts/DINPro-Medium.svg#webfont') format('svg');
}

body {
  overflow-x: hidden;
}

figure {
  margin: 0;
}