// Variables
@import '../../../sass/variables';
// Mixins
@import '../../../sass/mixins';

.header-container {
  position: relative;
  height: 135px;
  background-color: #4c4c4c;

  &.not-show-on-mobile {
    display: none;
  }

  .line-red {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 9px;
    background-color: #be0000;
  }

  .line-black {
    position: absolute;
    bottom: 9px;
    width: 100%;
    height: 25px;
    background-color: #222222;
  }

  &__content {
    position: relative;
    max-width: 1200px;
    height: 100%;
    background: url("https://depo.honvedfc.hu/web/bozsik-arena/header/header-bg.png") no-repeat;
    padding-left: 0;
    padding-right: 0;
  }

  .logo {
    z-index: 3;
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -113px;
  }

  @media all and (min-width: 768px) {
    &.not-show-on-mobile {
      display: block;
    }

  }
}

.mobile-header-container {
  .show {
    display: block;
  }

  .hidden {
    display: none;
  }

  .hamburger-container {
    position: absolute;
    width: 100%;
    top: 0;
    right: 10px;
    .hamburger-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 79px;
      padding-top: 22px;
      background-color: #222222;
      border-bottom-left-radius: 36px;
      border-bottom-right-radius: 36px;

      &.open {
        width: 60%;
        border-top-left-radius: 36px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        >div {
          margin-right: 12px;
          float: right;
        }
        .lines {
          background-color: #bc0f00;
        }

        @media all and (min-width: 480px) {
          width: 45%;
        }
      }

      .lines {
        margin: 0 0 4px 12px;
        width: 48px;
        height: 9px;
        background-color: #ffffff;
        border-radius: 5px;
      }
    }

    .main-navigation {
      ul {
        width: 60%;
        z-index: 1000;
        padding-left: 0;
        background-color: #222222;
        position: absolute;
        top: 113px;
        right: 0;
        border-bottom-left-radius: 28px;
        border-bottom-right-radius: 28px;

        @media all and (min-width: 480px) {
          width: 45%;
        }

        li {
          display: block;
          padding: 0;

          &.spacer {
            display: none;
          }

          a {
            display: block;
            padding: 14px 28px;
            transition: 250ms;
            user-select: none;
            -webkit-user-select: none;

            &:hover {
              color: #bc0f00 !important;
            }
          }
        }
      }
    }
  }

  .stadium {
    img {
      max-width: 100%;
    }
  }

  .logo {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -113px;
  }

  @media all and (min-width: 768px) {
    display: none;
  }
}